<template>
	<search-list-table ref="searchListTable" :columns="columns" :formSearchList="formSearchList" :initDataSource="initDataSource">
		<template slot="scopedSlots" slot-scope="{ columnData }">
			<span v-if="columnData.processStatus === 'ipf_info_check_fail'">
				<a-badge color="red" text="Rejected" />
				<a-tooltip placement="bottomRight">
					<template slot="title">
						<span>{{ columnData.checkText }}</span>
					</template>
					<a-icon type="question-circle" style="color: red; margin-left: 5px" />
				</a-tooltip>
			</span>
			<a-badge v-else color="yellow" text="Pending" />
		</template>
		<template slot="action" slot-scope="{ columnData }">
			<a @click="Edit(columnData.loanId)">{{ $t('page.edit') }}</a>
			<a-divider type="vertical" />
			<a @click="Detail(columnData.loanId, null)">{{ $t('page.checkDetail') }}</a>
			<a-divider type="vertical" />
			<a @click="Detail(columnData.loanId, '/home/insuranceRenewal/renewalList')">{{ $t('operate.sendAudit') }}</a>
		</template>
	</search-list-table>
</template>
<script>
import { apiBranchList } from '@/api/branch'
import { apiIpfApply } from '@/api/insuranceRenewal'
export default {
	data() {
		return {
			branchOfficeList: [],
			expireDayAsc: null
		}
	},
	computed: {
		formSearchList() {
			return [
				{
					label: 'table.applyInfo.cloums.caseId',
					type: 'input',
					validateField: ['caseId']
				},
				{
					label: 'table.applyInfo.cloums.userName',
					type: 'input',
					validateField: ['name']
				},
				{
					label: 'table.CustomerManagement.fromdata.licensePlateNumber',
					type: 'input',
					validateField: ['registrationNumber']
				},
				{
					label: 'table.applyInfo.cloums.IdNumber',
					type: 'input',
					validateField: ['identityNum']
				},
				{
					label: 'table.applyInfo.cloums.mobile',
					type: 'input',
					validateField: ['mobile']
				},
				{
					label: 'table.applyInfo.loanApplyLabel.branchOffice',
					type: 'select',
					validateField: ['officeId'],
					selectOption: this.branchOfficeList,
					dataPreType: {
						value: 'id',
						text: 'storeName'
					}
				},
				{
					label: 'table.applyInfo.cloums.statusOfRenew',
					type: 'select',
					validateField: ['processStatuses'],
					selectOption: [
						{
							label: 'Pending',
							value: 'ipf_info_input'
						},
						{
							label: 'Rejected',
							value: 'ipf_info_check_fail'
						}
					],
					dataPreType: {
						value: 'value',
						text: 'label'
					}
				}
			]
		},
		columns() {
			const that = this
			return [
				{
					// 案件ID
					title: 'No.',
					scopedSlots: { customRender: 'serial_number' },
					width: 80,
					align: 'center'
				},
				{
					// 案件ID
					title: this.$t('table.applyInfo.cloums.caseId'),
					dataIndex: 'caseId',
					align: 'center',
					customRender: (v, o) => {
						return o.casePrefix + '-' + o.caseId
					}
				},
				{
					title: this.$t('table.applyInfo.cloums.userName'),
					dataIndex: 'firstName',
					align: 'center',
					customRender: (v, o) => (v ? v : '') + ' ' + (o.middleName ? o.middleName : '') + ' ' + (o.lastName ? o.lastName : '')
				},
				{
					title: this.$t('table.CustomerManagement.fromdata.licensePlateNumber'),
					dataIndex: 'registrationNumbers',
					align: 'center',
					width: 150,
					customRender: (v) => (v ? v.join('/') : '-')
				},
				{
					title: this.$t('table.applyInfo.cloums.mobile'),
					dataIndex: 'mobiles',
					align: 'center',
					width: 150,
					customRender: (v) => (v ? v.join('/') : '')
				},
				{
					title: this.$t('table.applyInfo.cloums.IdNumber'),
					dataIndex: 'identityNum',
					align: 'center'
				},
				{
					title: this.$t('table.applyInfo.cloums.expireDays'),
					dataIndex: 'expireDays',
					align: 'center',
					sorter: true,
					width: 100
				},
				{
					title: this.$t('table.applyInfo.cloums.statusOfRenew'),
					align: 'center',
					scopedSlots: { customRender: 'scopedSlots' },
					width: 150
				},
				{
					// 操作
					title: this.$t('table.userFrom.columns.action'),
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: 250
				}
			]
		}
	},
	methods: {
		initDataSource(initParams, sorter) {
			if (sorter.columnKey === 'expireDays' && sorter.order) {
				this.expireDayAsc = sorter.order === 'ascend' ? 1 : 0
			} else {
				this.expireDayAsc = null
			}
			return apiIpfApply({ ...initParams, expireDayAsc: this.expireDayAsc })
		},
		// 编辑
		Edit(id) {
			this.$router.push({
				path: 'renewal',
				query: {
					id
				}
			})
		},
		// 查看详情
		Detail(id, from) {
			this.$router.push({
				path: 'renewalDetail',
				query: {
					id,
					from: from
				}
			})
		}
	},
	created() {
		this.$nextTick(() => {
			apiBranchList().then((res) => {
				this.branchOfficeList = res
			})
		})
	}
}
</script>
